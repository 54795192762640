/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    pre: "pre",
    code: "code",
    ul: "ul",
    li: "li",
    h3: "h3"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "nodejs",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#nodejs",
    "aria-label": "nodejs permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Node.js"), "\n", React.createElement(_components.p, null, "Use SVGR in Node.js to complex transformations or tools."), "\n", React.createElement("carbon-ad"), "\n", React.createElement(_components.h2, {
    id: "install",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#install",
    "aria-label": "install permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Install"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "npm install --save-dev @svgr/core\n# or use yarn\nyarn add --dev @svgr/core\n")), "\n", React.createElement(_components.h2, {
    id: "usage",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#usage",
    "aria-label": "usage permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Usage"), "\n", React.createElement(_components.p, null, "Import ", React.createElement(_components.code, null, "transform"), " from ", React.createElement(_components.code, null, "@svgr/core"), " to transform a file. It takes three arguments:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "source"), ": the SVG source code to transform"), "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "options"), ": the options used to transform the SVG"), "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "state"), ": a state linked to the transformation"), "\n"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "import { transform } from '@svgr/core'\n\nconst svgCode = `\n<svg xmlns=\"http://www.w3.org/2000/svg\"\n  xmlns:xlink=\"http://www.w3.org/1999/xlink\">\n  <rect x=\"10\" y=\"10\" height=\"100\" width=\"100\"\n    style=\"stroke:#ff0000; fill: #0000ff\"/>\n</svg>\n`\n\nconst jsCode = await transform(\n  svgCode,\n  { icon: true },\n  { componentName: 'MyComponent' },\n)\n")), "\n", React.createElement(_components.h3, {
    id: "options",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#options",
    "aria-label": "options permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Options"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://react-svgr.com/docs/options/"
  }, "SVGR options reference"), " describes all options available."), "\n", React.createElement(_components.h3, {
    id: "state",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#state",
    "aria-label": "state permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "State"), "\n", React.createElement(_components.p, null, React.createElement(_components.code, null, "state"), " argument have two main properties:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "componentName"), " (required): the name of the component that will be used in the generated component"), "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "filePath"), " (required): the name of the file that is generated, mainly used to find runtime config file to apply"), "\n"), "\n", React.createElement(_components.h2, {
    id: "use-svgo-and-prettier",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#use-svgo-and-prettier",
    "aria-label": "use svgo and prettier permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Use SVGO and Prettier"), "\n", React.createElement(_components.p, null, "By default ", React.createElement(_components.code, null, "@svgr/core"), " doesn't include ", React.createElement(_components.code, null, "svgo"), " and ", React.createElement(_components.code, null, "prettier"), " plugins. If you need these, you have to install it and to specify it in options."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "npm install @svgr/plugin-jsx @svgr/plugin-prettier\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "import { transform } from '@svgr/core'\n\nconst svgCode = `\n<svg xmlns=\"http://www.w3.org/2000/svg\"\n  xmlns:xlink=\"http://www.w3.org/1999/xlink\">\n  <rect x=\"10\" y=\"10\" height=\"100\" width=\"100\"\n    style=\"stroke:#ff0000; fill: #0000ff\"/>\n</svg>\n`\n\nconst jsCode = await transform(\n  svgCode,\n  {\n    plugins: ['@svgr/plugin-svgo', '@svgr/plugin-jsx', '@svgr/plugin-prettier'],\n    icon: true,\n  },\n  { componentName: 'MyComponent' },\n)\n")), "\n", React.createElement(_components.h2, {
    id: "synchronous-usage",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#synchronous-usage",
    "aria-label": "synchronous usage permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Synchronous usage"), "\n", React.createElement(_components.p, null, "SVGR provides two API, an asynchronous one and a synchronous one. It is recommended to use the asynchronous one as possible, but sometimes you may have to use the synchronous one. SVGR exposes ", React.createElement(_components.code, null, "transform.sync"), " for synchronous usage."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "import { transform } from '@svgr/core'\n\nconst svgCode = `\n<svg xmlns=\"http://www.w3.org/2000/svg\"\n  xmlns:xlink=\"http://www.w3.org/1999/xlink\">\n  <rect x=\"10\" y=\"10\" height=\"100\" width=\"100\"\n    style=\"stroke:#ff0000; fill: #0000ff\"/>\n</svg>\n`\n\nconst jsCode = transform.sync(\n  svgCode,\n  { icon: true },\n  { componentName: 'MyComponent' },\n)\n")), "\n", React.createElement(_components.h2, {
    id: "note-for-tools-creator",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#note-for-tools-creator",
    "aria-label": "note for tools creator permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Note for tools creator"), "\n", React.createElement(_components.p, null, "If you want to create a tool based on SVGR, you must follow some rules:"), "\n", React.createElement(_components.h3, {
    id: "specify-statecaller",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#specify-statecaller",
    "aria-label": "specify statecaller permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Specify ", React.createElement(_components.code, null, "state.caller")), "\n", React.createElement(_components.p, null, "If you create a tool based on SVGR, it is always better to specify ", React.createElement(_components.code, null, "state.caller"), ". It permits the inter-operability betweens plugins. If someone create a SVGR plugin it could adapt it specifically to your tool."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "interface State {\n  filePath?: string\n  componentName: string\n  caller?: {\n    name?: string\n    previousExport?: string | null\n    defaultPlugins?: ConfigPlugin[]\n  }\n}\n")), "\n", React.createElement(_components.p, null, "Aditionnaly you can specify ", React.createElement(_components.code, null, "defaultPlugins"), " if your tool needs plugins by default and you still want users to be able to customize it."), "\n", React.createElement(_components.h3, {
    id: "use-asynchronous-api",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#use-asynchronous-api",
    "aria-label": "use asynchronous api permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Use asynchronous API"), "\n", React.createElement(_components.p, null, "Asynchronous API uses Node.js asychronous API under the hood, it is more performant. So it is always better to use that API if possible."), "\n", React.createElement(_components.h3, {
    id: "do-not-overrides-svgr-options",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#do-not-overrides-svgr-options",
    "aria-label": "do not overrides svgr options permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Do not overrides SVGR options"), "\n", React.createElement(_components.p, null, "SVGR has lot of options, every options are describe in this documentation. It is always easier for users to refer to this documentation."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Always prefer asynchronous API if possible"), "\n", React.createElement(_components.li, null, "Do not override SVGR options, it is easier for users to refer to this documentation"), "\n", React.createElement(_components.li, null, "Specify ", React.createElement(_components.code, null, "state.caller"), " argument:"), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
